import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/apm";

const enabledInDevelopment = true;

if (window.gon.env !== "development" || enabledInDevelopment) {
  Sentry.init({
    dsn: `https://${window.gon.sentry_dsn}@o398968.ingest.sentry.io/5402581`,
    environment: window.gon.env,
    integrations: [new Integrations.Tracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: ["NetworkError"], // Ignore sentry requests blocked by adblocker and avoid re-throwing them infinetely
  });
}
