import * as Sentry from "@sentry/react";

function browserSupportsAllFeatures() {
  return "forEach" in Array.prototype && "entries" in Object;
}

let DOMLoaded = false;
let jsFeaturesReady = false;
let jsReadyDispatched = false;

function dispatchIfJsReady() {
  if (DOMLoaded && jsFeaturesReady && !jsReadyDispatched) {
    jsReadyDispatched = true;

    let event;
    // support for IE 11
    if (document.createEvent) {
      event = document.createEvent("Event");
      event.initEvent("js-ready", true, true);
    } else {
      event = new Event("js-ready");
    }

    document.dispatchEvent(event);

    Sentry.addBreadcrumb({
      category: "browserSupport",
      message: "JS Ready",
      level: Sentry.Severity.Debug,
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  DOMLoaded = true;
  dispatchIfJsReady();
});

if (browserSupportsAllFeatures()) {
  jsFeaturesReady = true;
  dispatchIfJsReady();
} else {
  // eslint-disable-next-line
  window.callbackPolyfillsLoaded = () => {
    jsFeaturesReady = true;

    Sentry.addBreadcrumb({
      category: "browserSupport",
      message: "Polyfills loaded",
      level: Sentry.Severity.Debug,
    });

    dispatchIfJsReady();
  };

  const script = document.createElement("script");
  script.src =
    "https://polyfill.io/v3/polyfill.min.js?version=3.111.0&callback=callbackPolyfillsLoaded&features=Array.prototype.forEach%2CObject.entries";
  script.crossorigin = "anonymous";

  document.head.appendChild(script);
}
